import React, { useState, useEffect, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import "./pharmay.scss";
import Stack from "@mui/material/Stack";
import {
  getAdminBusiness,
  getAdminBusinessList,
} from "../../services/business";
import { useDispatch, useSelector } from "react-redux";
import API from "../../shared/httpService/config";
import Pagination from "../../shared/components/Pagination";
import useDialogModal from "../../hooks/useDialogModal";
import BusinessDetails from "./businessDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation } from "react-router";
import StatusFilter from "../../shared/components/CustomFilters/customFilter";
import URLS from "../../shared/constants/urls";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { AgGridReact } from "ag-grid-react";

export const Businesses = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let queryStr = new URLSearchParams(location?.search);
  const modId = queryStr?.get("modId");
  const randomId = queryStr?.get("ranId");
  const response = useSelector(
    (state) => state?.business?.businesses?.response
  );
  const loading = useSelector((state) => state?.business?.businesses?.loading);
  const [search, setSearch] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [businessesData, setbusinessesData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [BusinessDetailDialog, showBusinessDetailDialog, closeBusinessDialog] =
    useDialogModal(BusinessDetails);

  const [gridApi, setGridApi] = useState(null);
  const filterOptions = ["contains", "equals"];
  const buttons = ["reset", "apply"];

  useEffect(() => {
    if (modId) {
      dispatch(getAdminBusiness(modId));
      showBusinessDetailDialog();
    }
  }, [modId, randomId]);

  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
    }
  }, [response]);

  const handlePageChange = useCallback((e, value) => {
    console.log("object", value && value.property);
    if (value) {
      dispatch(
        getAdminBusinessList(
          search ? search : "",
          "",
          value,
          limit,
          function (res) {}
        )
      );
      setPage(value);
    }
  }, []);

  const containerStyle = useMemo(
    () => ({ width: "100%", height: "330px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "business_name",
      flex: 1.3,

      filterParams: {
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
    },
    {
      field: "email",
      flex: 1.5,
      filterParams: {
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
    },
    {
      field: "mobile_no",
      flex: 1,
      filterParams: {
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
    },
    {
      field: "city",
      flex: 1,
      filterParams: {
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
    },
    {
      field: "country",
      flex: 1,
      filterParams: {
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
    },
    {
      field: "status",
      flex: 1,
      filter: StatusFilter,
      filterParams: {
        values: [2000, 2004, 2006],
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
    },
    {
      field: "Action",
      flex: 1,
      headerClass: "no-filter-icon",
      cellRenderer: (params) => {
        return (
          <Box>
            <IconButton
              variant="text"
              onClick={() => {
                setBusinessId(params?.data?._id);
                showBusinessDetailDialog();
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const datasource = {
    getRows(params) {
      console.log(JSON.stringify(params.request, null, 1));
      const { startRow, endRow, filterModel, sortModel } = params.request;

      let pageNo = Math.floor(startRow / limit) + 1;
      let url = `${URLS?.BUSINESSES}?page=${pageNo}&limit=${limit}`;

      API.post(`${url}`, { filters: filterModel })
        .then(function (response) {
          setbusinessesData(response?.data);
          params.success({
            rowData: response?.data?.businesses,
            rowCount: response?.data?.count,
          });
        })
        .catch(function (error) {
          console.log(error);
          params.failCallback();
        });
    },
  };

  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  };

  function clearFilters() {
    if (gridApi) {
      gridApi?.api?.setFilterModel(null);
      const statusFilterInstance = gridApi?.api?.getFilterInstance("status");
      if (statusFilterInstance && statusFilterInstance?.resetFilter) {
        statusFilterInstance?.resetFilter();
      }
    } else {
      console.error("Grid API is not available");
    }
  }

  return (
    <Card className="admin-card">
      <Box className="admin-card-header">
        <Typography variant="h5" sx={{ flex: "1 1 100%" }}>
          Businesses
        </Typography>
        <Box
          width="100%"
          textAlign="right"
          alignSelf="center"
          className="restBtnMain"
        >
          <Button
            variant="contained"
            onClick={clearFilters}
            className="resetbtn"
          >
            Reset Filters
          </Button>
        </Box>
      </Box>
      <CardContent>
        <Box
          className="table-card"
          sx={{ width: "100%", height: "calc(100vh - 300px)", px: "20px" }}
        >
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box style={containerStyle}>
              <Box style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  pagination={true}
                  paginationPageSize={limit}
                  cacheBlockSize={limit}
                  animateRows={true}
                  ensureDomOrder={true}
                  enableCellTextSelection={true}
                  onGridReady={onGridReady}
                  rowModelType="serverSide"
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box className="card-table" sx={{ width: "100%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {businessesData &&
                businessesData?.businesses?.length > 0 &&
                businessesData?.businesses?.map((params, ind) => {
                  return (
                    <Accordion key={ind}>
                      <AccordionSummary
                        expandIcon={false}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ paddingX: 1 }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                              <Box flexDirection={"column"} width={"50%"}>
                                <Typography className="card-field-12">
                                  Business Name
                                </Typography>
                                <Typography className="card-field-14 text-ellipses">
                                  {params?.business_name}
                                </Typography>
                              </Box>
                              <Box
                                flexDirection={"column"}
                                width={"50%"}
                                justifyContent={"flex-end"}
                                textAlign={"end"}
                              >
                                <Typography className="card-field-12">
                                  Status
                                </Typography>
                                <Typography className="card-field-14 text-ellipses">
                                  {params?.status}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "8px !important" }}>
                        <Box display="flex" alignItems="center">
                          <Box flexDirection={"column"} width={"50%"}>
                            <Typography className="card-field-12">
                              Email
                            </Typography>
                            <Typography className="card-field-14 text-ellipses">
                              {params?.email}
                            </Typography>
                          </Box>
                          <Box
                            flexDirection={"column"}
                            width={"50%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              Mobile No#
                            </Typography>
                            <Typography className="card-field-14 text-ellipses">
                              {params?.mobile_no}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexDirection={"column"} width={"50%"}>
                            <Typography className="card-field-12">
                              City
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {params?.city}
                            </Typography>
                          </Box>
                          <Box
                            flexDirection={"column"}
                            width={"50%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              Country
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {params?.country}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box
                            flexDirection={"column"}
                            width={"100%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              Action
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <IconButton
                                sx={{ padding: 0 }}
                                onClick={() => {
                                  setBusinessId(params?._id);
                                  showBusinessDetailDialog();
                                }}
                              >
                                <OpenInNewIcon sx={{ color: "#1E1E1E" }} />
                              </IconButton>
                            </Typography>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </>
          )}
        </Box>
        <Stack spacing={2} sx={{ display: { xs: "block", md: "none" } }}>
          <Pagination
            totalCount={businessesData?.count}
            page={page}
            onPageChange={handlePageChange}
          />
        </Stack>
      </CardContent>

      <BusinessDetailDialog
        business={modId ? modId : businessId}
        page={page}
        modId={modId}
        search={search}
        limit={limit}
      />
    </Card>
  );
};

export default Businesses;
