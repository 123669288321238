import React, { useEffect, useState } from "react";
import "./queries.scss";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { getAdminQueriesDetail } from "../../services/dasboard";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const QueriesDetail = ({ open, onClose, pharmacy }) => {
  const dispatch = useDispatch();

  const response = useSelector(
    (state) => state?.dashboard?.queriesDetail?.response
  );
  const loading = useSelector(
    (state) => state?.dashboard?.queriesDetail?.loading
  );

  const [state, setState] = useState({
    store: {},
  });

  useEffect(() => {
    dispatch(getAdminQueriesDetail(pharmacy));
  }, [pharmacy]);

  useEffect(() => {
    setState({ ...state, store: response });
  }, [response]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-mui">
        {loading ? (
          <Box sx={{ position: "absolute", top: "45%", left: "46%" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box className="modal-header-mui">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Queries
              </Typography>
              <IconButton
                className="modal-clear-btn"
                onClick={() => {
                  onClose();
                }}
              >
                <ClearIcon />
              </IconButton>
              <Divider />
            </Box>
            <Box className="modal-content-mui">
              <Grid container>
                <Grid item xs={12}>
                  <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ fontSize: "14px" }}
                      >
                        Query Details
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        variant="body2"
                        sx={{ color: "#7E7E7E", fontWeight: "500" }}
                      >
                        Full Name
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "500" }}>
                        {state?.store?.first_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        sx={{ color: "#7E7E7E", fontWeight: "500" }}
                      >
                        Last Name
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "500" }}>
                        {state?.store?.last_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                      <Typography
                        variant="body2"
                        sx={{ color: "#7E7E7E", fontWeight: "500" }}
                      >
                        Email
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "500" }}>
                        {state?.store?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} mt={2}>
                      <Typography
                        variant="body2"
                        sx={{ color: "#7E7E7E", fontWeight: "500" }}
                      >
                        Mobile N0#
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "500" }}>
                        {state?.store?.mobile_no}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default QueriesDetail;
