import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default forwardRef((props, ref) => {
  const [immuteableOptions, setImmuteableOptions] = useState([
    { title: "Pending", value: "pending", checked: false },
    { title: "Approved", value: "approved", checked: false },
    { title: "Rejected", value: "rejected", checked: false },
    { title: "Suspended", value: "suspended", checked: false },
  ]);
  const [searchValue, setSearchValue] = useState("");

  const [selectedFilters, setSelectedFilters] = useState([]);

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return selectedFilters?.length;
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return {
          value: selectedFilters,
          filter: selectedFilters,
          type: "equals",
        };
      },

      setModel(model) {
        setSelectedFilters(model == null ? null : model.value);
      },
      resetFilter,
    };
  });

  const handleChange = (option) => {
    const updatedOptions = immuteableOptions.map((item) =>
      item.title === option.title ? { ...item, checked: !item.checked } : item
    );
    setImmuteableOptions(updatedOptions);
    setSelectedFilters(
      updatedOptions.filter((item) => item.checked).map((el) => el.value)
    );
  };

  const resetFilter = () => {
    setImmuteableOptions(
      immuteableOptions.map((item) => ({ ...item, checked: false }))
    );
    setSelectedFilters([]);
    console.log(ref.current);

    setTimeout(() => {
      props.filterChangedCallback();
    }, 100);
  };

  const applyFilter = () => {
    props.filterChangedCallback();
  };

  return (
    <Box padding={2}>
      <input
        label="Search"
        placeholder="Filter..."
        value={searchValue}
        onChange={onSearchChange}
        className="ag-input-field-input ag-text-field-input"
      />

      <FormGroup sx={{ padding: "10px 0px" }}>
        {immuteableOptions
          ?.filter((option) =>
            option.title.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((item, indx) => {
            return (
              <FormControlLabel
                key={indx}
                control={
                  <Checkbox
                    sx={{ padding: "3px 10px" }}
                    checked={item.checked}
                    // onChange={onApprovedChange}
                    onChange={(e) => handleChange(item)}
                  />
                }
                label={item?.title}
              />
            );
          })}
      </FormGroup>
      <Box textAlign="right">
        <button
          className="ag-button ag-standard-button ag-filter-apply-panel-button"
          onClick={resetFilter}
        >
          Reset
        </button>
        <button
          className="ag-button ag-standard-button ag-filter-apply-panel-button"
          onClick={applyFilter}
        >
          Apply
        </button>
      </Box>
    </Box>
  );
});
