import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Stack from "@mui/material/Stack";
import { getAdminQueriesList, setPharmacyID } from "../../services/dasboard";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../shared/components/Pagination";
import useDialogModal from "../../hooks/useDialogModal";
import PharmacyDetails from "./queriesDetails";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "./queries.scss";

export const Queries = () => {
  const dispatch = useDispatch();

  const response = useSelector((state) => state?.dashboard?.queries?.response);
  const loading = useSelector((state) => state?.dashboard?.queries?.loading);

  const [customLoading, setCustomLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [pharmacyId, setPharmacyId] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [state, setState] = useState({
    queries: response,
    count: 0,
  });
  const [PharmacyDetailDialog, showPharmacyDetailDialog, closePharmacyDialog] =
    useDialogModal(PharmacyDetails);

  useEffect(() => {
    dispatch(
      getAdminQueriesList("", "", page, limit, function (res) {
        if (res) {
          setCustomLoading(false);
        }
      })
    );
  }, []);

  useEffect(() => {
    const count = response?.count;
    const perPage = 10;
    const buttonsCount = Math.ceil(count / perPage);
    setState({
      ...state,
      queries: response.queries,
      count: buttonsCount,
    });
  }, [response]);

  const handlePageChange = useCallback((e, value) => {
    dispatch(
      getAdminQueriesList(
        search ? search : "",
        "",
        value,
        limit,
        function (res) {}
      )
    );
    setPage(value);
    setCustomLoading(false);
  }, []);

  let columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Typography className="rowText">
              {params?.row?.first_name ? params?.row?.first_name : "N/A"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Typography className="rowText">
              {params?.row?.last_name ? params?.row?.last_name : "N/A"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Typography className="rowText">
              {params?.row?.email ? params?.row?.email : "N/A"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "mobile_no",
      headerName: "Mobile N0#",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Typography className="rowText">
              {params?.row?.mobile_no ? params?.row?.mobile_no : "N/A"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <>
              <IconButton
                variant="text"
                onClick={() => {
                  setPharmacyId(params?.row?._id);
                  showPharmacyDetailDialog();
                  dispatch(setPharmacyID(params?.row?._id));
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </>
          </Box>
        );
      },
    },
  ];

  return (
    <Card className="admin-card">
      <Box className="admin-card-header">
        <Typography variant="h5" sx={{ flex: "1 1 100%" }}>
          Queries
        </Typography>
      </Box>
      <CardContent>
        <Box
          className="table-card"
          sx={{ height: "calc(100vh - 300px)", px: "20px" }}
        >
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={
                state?.queries && state?.queries?.length > 0
                  ? state?.queries
                  : []
              }
              columns={columns}
              hideFooter={true}
              hideFooterRowCount={true}
            />
          )}
        </Box>
        <Box className="card-table" sx={{ width: "100%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {state?.queries &&
                state?.queries?.length > 0 &&
                state?.queries?.map((params, ind) => {
                  return (
                    <Accordion key={ind}>
                      <AccordionSummary
                        expandIcon={false}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ paddingX: 1 }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Box flexDirection={"column"} width={"100%"}>
                              <Typography className="card-field-12">
                                Email
                              </Typography>
                              <Typography className="card-field-14 text-ellipses">
                                {params?.email}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "8px !important" }}>
                        <Box display="flex" alignItems="center">
                          <Box flexDirection={"column"} width={"50%"}>
                            <Typography className="card-field-12">
                              First Name
                            </Typography>
                            <Typography className="card-field-14 text-ellipses">
                              {params?.first_name ? params?.first_name : "N/A"}
                            </Typography>
                          </Box>
                          <Box
                            flexDirection={"column"}
                            width={"50%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              Last Name
                            </Typography>
                            <Typography className="card-field-14 text-ellipses">
                              {params?.last_name ? params?.last_name : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexDirection={"column"} width={"50%"}>
                            <Typography className="card-field-12">
                              email
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {params?.email}
                            </Typography>
                          </Box>
                          <Box
                            flexDirection={"column"}
                            width={"50%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              mobile_no
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {params?.mobile_no || "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box
                            flexDirection={"column"}
                            width={"100%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              Action
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <IconButton
                                sx={{ padding: 0 }}
                                onClick={() => {
                                  setPharmacyId(params?._id);
                                  showPharmacyDetailDialog();
                                  dispatch(setPharmacyID(params?._id));
                                }}
                              >
                                <OpenInNewIcon sx={{ color: "#1E1E1E" }} />
                              </IconButton>
                            </Typography>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </>
          )}
        </Box>
        {!customLoading && (
          <Stack spacing={2}>
            <Pagination
              totalCount={state?.count}
              page={page}
              onPageChange={handlePageChange}
            />
          </Stack>
        )}
      </CardContent>

      <PharmacyDetailDialog
        pharmacy={pharmacyId}
        page={page}
        search={search}
        limit={limit}
      />
    </Card>
  );
};

export default Queries;
