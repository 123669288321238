import { useContext, useEffect, useMemo, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import "./sidebar.scss";
import { ListItemIcon, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import dashboardIcon from "../../../assets/images/dahsboard.svg";
import {
  connectionWithSocketServer,
  socketServer,
} from "../../../realtimeCommunication/socketConnection";
import userProfile from "../../../assets/images/userProfile.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import nxusLogo from "../../../assets/logo1.svg";

import sideNavData from "../../../nav";

const drawerWidth = 240;

export const Sidebar = () => {
  const location = useLocation();
  const history = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const isActive = (value) => (location?.pathname === value ? "active" : "");
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: "1201",
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Box
        className="headerLogoContainer"
        onClick={() => history("/marketplace")}
      >
        <img src={nxusLogo} alt="logo" />
        <Typography color="#ffff !important" className="logoText">
          UnioRx
        </Typography>
      </Box>
      <Box sx={{ overflow: "auto" }}>
        <List>
          {sideNavData && sideNavData.length > 0 ? (
            sideNavData.map((item, index) => (
              <ListItem
                sx={{ padding: "8px 0px 0px 8px" }}
                key={index}
                disablePadding
                className={isActive(`${item?.link_to}`)}
                onClick={() => history(`${item?.link_to}`, { replace: true })}
              >
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {item?.icon}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography fontSize="17px">{item?.name}</Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <ListItem
              disablePadding
              className={isActive("/profile")}
              onClick={() => history("/dash/profile", { replace: true })}
            >
              <ListItemButton>
                <ListItemIcon>
                  <img src={userProfile} />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
