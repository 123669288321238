import React from "react";
import { Box, Card, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "./orderDetailStepper.scss";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      <img
        src={props?.profile_img ? props?.profile_img : null}
        className="stepper_profile_img"
      />
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const OrderDetailStepper = ({ stateOrder }) => {
  const modifyDateTime = (str) => {
    if (str) {
      const mydate = str.split("T")[0];
      var time = str.split("T")[1];
      var splTime = time.split(":");
      const convert = mydate.split("-");
      return (
        convert[2] +
        "/" +
        convert[1] +
        "/" +
        convert[0] +
        "  " +
        splTime[0] +
        ":" +
        splTime[1]
      );
    }
  };

  return (
    stateOrder &&
    stateOrder?.jobId && (
      <Card sx={{ width: "100%", marginTop: "1rem" }}>
        <Typography
          variant="h4"
          sx={{
            color: "#101828",
            fontWeight: "500",
            fontSize: "16px",
            margin: "1rem",
          }}
        >
          Delivery Status
        </Typography>

        <Stack sx={{ width: "980px", overflowX: "auto" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={stateOrder?.stepper_status?.length}
            connector={<ColorlibConnector />}
          >
            {stateOrder?.stepper_status?.map((label, i) => (
              <Step key={i}>
                <StepLabel
                  StepIconProps={label}
                  StepIconComponent={ColorlibStepIcon}
                >
                  <p>
                    {label?.status_name_en}
                    <br />
                    {label?.dateTime && (
                      <span>{modifyDateTime(label?.dateTime)}</span>
                    )}
                    <br />
                    {label?.driver && (
                      <Box sx={{ whiteSpace: "nowrap" }}>
                        <Typography
                          component="span"
                          color="#d32f2f"
                          fontWeight={500}
                        >
                          Driver:{" "}
                        </Typography>
                        {label?.driver}
                      </Box>
                    )}
                  </p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Card>
    )
  );
};

export default OrderDetailStepper;
