import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import "../dashboard.scss";
import { getMonthlySaleReport } from "../../../services/dasboard";
import Chart from "react-apexcharts";
import { formatNumberWithCommas } from "../../../shared/utils/formatedDate";

const MonthlySaleGraph = () => {
  const dispatch = useDispatch();

  const [selectedMonths, setSelectedMonths] = useState("day");
  const [state, setState] = useState({
    chartData: [],
    chartOptions: {},
  });

  const handleChange = (value) => {
    if (value) {
      setSelectedMonths(value);
    }
  };

  const monthlySalesLoading = useSelector(
    (state) => state?.dashboard?.monthlySaleReport?.loading
  );

  useEffect(() => {
    dispatch(
      getMonthlySaleReport(selectedMonths, function (res) {
        if (res?.status == "success") {
          let graphData = res?.data;

          if (graphData) {
            let chartData = graphData?.staticData;

            const options = {
              chart: {
                height: 350,
                type: "area",
                toolbar: {
                  show: false,
                },
              },
              stroke: {
                width: 2.5,
                dashArray: [0, 0],
              },
              grid: {
                borderColor: "#ebebeb",
                strokeDashArray: 7,
              },
              fill: {
                type: "gradient",
                gradient: {
                  shade: true,
                  shadeIntensity: 0.7,
                  gradientToColors: undefined,
                  inverseColors: true,
                  opacityFrom: 0.4,
                  opacityTo: 0.1,
                  stops: [0, 100],
                },
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              xaxis: {
                type: "datetime",
                categories: graphData?.categories,
                crosshairs: {
                  show: true,
                  width: 1,
                  position: "front",
                  opacity: 0.5,
                  stroke: {
                    color: "#235D5E",
                    width: 2,
                    dashArray: 0,
                  },
                  fill: {
                    type: "gradient",
                    color: "#235D5E",
                    gradient: {
                      colorFrom: "#235D5E",
                      colorTo: "#235D5E",
                      stops: [0, 100],
                      opacityFrom: 0.6,
                      opacityTo: 0.9,
                    },
                  },
                  dropShadow: {
                    enabled: false,
                    top: 0,
                    left: 0,
                    blur: 1,
                    opacity: 0.4,
                  },
                },
              },

              tooltip: {
                enabled: true,
                shared: true,
                x: {
                  show: true,
                  format: "dd MMM yyyy",
                  formatter: function (
                    value,
                    { series, seriesIndex, dataPointIndex, w }
                  ) {
                    const monthsInEnglish = [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December",
                    ];
                    const dateIndex = dataPointIndex;
                    const dateString =
                      w?.globals?.categoryLabels[dateIndex]?.length > 0
                        ? w?.globals?.categoryLabels[dateIndex]
                        : w?.config?.xaxis?.categories[dateIndex];
                    const date = new Date(dateString);
                    const year = date.getFullYear();
                    const month = monthsInEnglish[date.getMonth()];
                    const tooltipTitle = month + " " + year;
                    return tooltipTitle;
                  },
                },
                y: {
                  formatter: function (val) {
                    return (
                      "$" +
                      formatNumberWithCommas(
                        parseFloat(Number((val * 100) / 100)).toFixed(2)
                      )
                    );
                  },
                },
              },
            };

            setState({
              ...state,
              chartOptions: options,
              chartData: chartData,
            });
          }
        }
      })
    );
  }, [selectedMonths]);

  return (
    <Card className="admin-card-revenue">
      <Box display="flex" width="100%" justifyContent="space-around">
        <Box width="100%" pr={{ xs: 0, sm: 2 }}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={4}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: "#101828",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Monthly Sales Revenue
              </Typography>
            </Grid>
            <Grid item xs={8} textAlign="right" className="cusBtnMonth">
              <Button
                variant="outlined"
                className={
                  selectedMonths === "day" ? "activeMonth" : "cusMonthBtn"
                }
                onClick={() => handleChange("day")}
              >
                Day
              </Button>
              <Button
                variant="outlined"
                className={
                  selectedMonths === "week" ? "activeMonth" : "cusMonthBtn"
                }
                onClick={() => handleChange("week")}
              >
                Week
              </Button>
              <Button
                variant="outlined"
                className={
                  selectedMonths === "month" ? "activeMonth" : "cusMonthBtn"
                }
                onClick={() => handleChange("month")}
              >
                Month
              </Button>
              <Button
                variant="outlined"
                className={
                  selectedMonths === "year" ? "activeMonth" : "cusMonthBtn"
                }
                onClick={() => handleChange("year")}
              >
                Year
              </Button>
            </Grid>
          </Grid>

          {monthlySalesLoading ? (
            <Box
              minHeight="350px"
              maxHeight="350px"
              justifyContent="center"
              display="flex"
              alignItems="center"
            >
              <CircularProgress sx={{ color: "#235D5E" }} />
            </Box>
          ) : (
            <>
              {state?.chartData && state?.chartData?.length > 0 ? (
                <Box id="chart" className="cusAreaChart">
                  <Chart
                    options={state?.chartOptions}
                    series={state?.chartData || []}
                    type="area"
                    height={350}
                  />
                </Box>
              ) : (
                <Box
                  minHeight="350px"
                  maxHeight="350px"
                  justifyContent="center"
                  display="flex"
                  alignItems="center"
                >
                  No revenue are available!
                </Box>
              )}
            </>
          )}
        </Box>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: "#E7E8EA !important",
            display: {
              lg: "block",
              xs: "none",
            },
          }}
        />
      </Box>
    </Card>
  );
};

export default MonthlySaleGraph;
