import React, { useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import "./pharmay.scss";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useDispatch, useSelector } from "react-redux";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment/moment";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router";
import API from "../../shared/httpService/config";
import URLS from "../../shared/constants/urls";
import { AgGridReact } from "ag-grid-react";
import StatusFilter from "../../shared/components/CustomFilters/customOrderFilter";
import { getAdminOrdersList } from "../../services/orders";
import Pagination from "../../shared/components/Pagination";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state?.orders?.orders?.loading);
  const [gridApi, setGridApi] = useState(null);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [businessesData, setbusinessesData] = useState([]);
  const [page, setPage] = useState(1);

  const handlePageChange = useCallback((e, value) => {
    if (value) {
      dispatch(
        getAdminOrdersList(
          search ? search : "",
          "",
          value,
          limit,
          function (res) {}
        )
      );
      setPage(value);
    }
  }, []);

  const containerStyle = useMemo(
    () => ({ width: "100%", height: "330px" }),
    []
  );

  const filterOptions = ["equals"];
  const buttons = ["reset", "apply"];

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "order_no",
      headerName: "Order",

      flex: 2,
      filterParams: {
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
      cellRenderer: (params) => {
        return (
          <Box>
            <Typography variant="caption" sx={{ flex: "1 1 100%" }}>
              {params?.data?.order_no}
              {params?.data?.orderedBy && params?.data?.orderedBy?.length
                ? params?.data?.orderedBy[0]?.pharmacy_name
                : ""}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "subOrders",
      headerName: "SubOrders",
      flex: 3,
      filterParams: {
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
      cellRenderer: (params) => {
        return (
          <Box>
            {params?.data?.subOrders && params?.data?.subOrders?.length
              ? params?.data?.subOrders?.map((el) => (
                  <Box>
                    <Typography variant="caption">
                      {IconForStatus(el?.orderStatus)} {el?.order_no} (in{" "}
                      {el?.orderedTo?.store_name} store)
                    </Typography>
                  </Box>
                ))
              : "N/A"}
          </Box>
        );
      },
    },

    {
      field: "order_date",
      headerName: "Date",
      filter: "agDateColumnFilter",
      flex: 2,
      inRangeInclusive: true,
      filterParams: {
        inRangeInclusive: true,
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
      cellRenderer: (params) => {
        return (
          <Box>
            <Typography variant="caption">
              {`${moment(params?.data?.order_date).format("YYYY-MM-DD")}`}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "orderStatus",
      headerName: "Status",
      flex: 2,
      filter: StatusFilter,
      filterParams: {
        filterOptions: filterOptions,
        buttons: buttons,
        maxNumConditions: 1,
      },
    },

    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      headerClass: "no-filter-icon",
      cellRenderer: (params) => {
        return (
          <Box>
            <Typography variant="caption">
              ${Number(params?.data?.total).toFixed(2)}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "MarketPlaceFee",
      headerName: "MarketPlaceFee",
      flex: 1,
      headerClass: "no-filter-icon",
      cellRenderer: (params) => {
        return (
          <Box>
            <Typography variant="caption">
              {params?.data?.marketPlaceFee
                ? "$" +
                  Number(params?.data?.marketPlaceFee?.fee_amount).toFixed(2)
                : "N/A"}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "Paid",
      headerName: "Paid",
      flex: 1,
      headerClass: "no-filter-icon",
      cellRenderer: (params) => {
        return (
          <Box>
            <Typography variant="caption">
              <Checkbox size="small" />
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "Action",
      flex: 1,
      headerClass: "no-filter-icon",
      cellRenderer: (params) => {
        return (
          <Box>
            <IconButton
              variant="text"
              onClick={() =>
                navigate({
                  pathname: "/orderDetail",
                  search: `?id=${params?.data?._id}`,
                })
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const IconForStatus = (status) => {
    switch (status) {
      case "Order declined":
        return <CancelIcon sx={{ color: "red" }} />;
      case "Completed":
        return <VerifiedIcon color="success" />;
      case "Refunded":
        return <CurrencyExchangeIcon color="secondary" />;

      default:
        return <RemoveCircleIcon sx={{ color: "yellow" }} />;
    }
  };

  const datasource = {
    getRows(params) {
      console.log(JSON.stringify(params.request, null, 1));
      const { startRow, endRow, filterModel, sortModel } = params.request;

      let pageNo = Math.floor(startRow / limit) + 1;
      let url = `${URLS?.ORDERS}?page=${pageNo}&limit=${limit}`;

      API.post(`${url}`, { filters: filterModel })
        .then(function (response) {
          setbusinessesData(response?.data);
          params.success({
            rowData: response?.data?.orders,
            rowCount: response?.data?.count,
          });
        })
        .catch(function (error) {
          console.log(error);
          params.failCallback();
        });
    },
  };

  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  };

  function clearFilters() {
    if (gridApi) {
      gridApi?.api?.setFilterModel(null);
      const statusFilterInstance =
        gridApi?.api?.getFilterInstance("orderStatus");
      if (statusFilterInstance && statusFilterInstance?.resetFilter) {
        statusFilterInstance?.resetFilter();
      }
    } else {
      console.error("Grid API is not available");
    }
  }

  return (
    <Card className="admin-card">
      <Box className="admin-card-header">
        <Typography variant="h5" sx={{ flex: "1 1 100%" }}>
          Orders
        </Typography>
        <Box
          width="100%"
          textAlign="right"
          alignSelf="center"
          className="restBtnMain"
        >
          <Button
            variant="contained"
            onClick={clearFilters}
            className="resetbtn"
          >
            Reset Filters
          </Button>
        </Box>
      </Box>
      <CardContent>
        <Box
          className="table-card"
          sx={{ height: "calc(100vh - 300px)", px: "20px" }}
        >
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box style={containerStyle}>
              <Box style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  pagination={true}
                  paginationPageSize={limit}
                  ensureDomOrder={true}
                  enableCellTextSelection={true}
                  cacheBlockSize={limit}
                  animateRows={true}
                  onGridReady={onGridReady}
                  rowModelType="serverSide"
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box className="card-table" sx={{ width: "100%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {businessesData &&
                businessesData?.orders?.length > 0 &&
                businessesData?.orders?.map((params, ind) => {
                  return (
                    <Accordion key={ind}>
                      <AccordionSummary
                        expandIcon={false}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ paddingX: 1 }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                              <Box flexDirection={"column"} width={"50%"}>
                                <Typography className="card-field-12">
                                  Order No#
                                </Typography>
                                <Typography className="card-field-14 text-ellipses">
                                  {params?.order_no}
                                  {params?.orderedBy &&
                                  params?.orderedBy?.length
                                    ? params?.orderedBy[0]?.pharmacy_name
                                    : ""}
                                </Typography>
                              </Box>
                              <Box
                                flexDirection={"column"}
                                width={"50%"}
                                justifyContent={"flex-end"}
                                textAlign={"end"}
                              >
                                <Typography className="card-field-12">
                                  Status
                                </Typography>
                                <Typography className="card-field-14 text-ellipses">
                                  {params?.orderStatus}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "8px !important" }}>
                        <Box display="flex" alignItems="center">
                          <Box flexDirection={"column"} width={"50%"}>
                            <Typography className="card-field-12">
                              SubOrders
                            </Typography>
                            <Typography className="card-field-14 text-ellipses">
                              {params?.subOrders && params?.subOrders?.length
                                ? params?.subOrders?.map((el) => (
                                    <Box>
                                      <Typography variant="caption">
                                        {IconForStatus(el?.orderStatus)}{" "}
                                        {el?.order_no} (in{" "}
                                        {el?.orderedTo?.store_name} store)
                                      </Typography>
                                    </Box>
                                  ))
                                : "N/A"}
                            </Typography>
                          </Box>
                          <Box
                            flexDirection={"column"}
                            width={"50%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              Order Date
                            </Typography>
                            <Typography className="card-field-14 text-ellipses">
                              {`${moment(params?.order_date).format(
                                "YYYY-MM-DD"
                              )}`}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexDirection={"column"} width={"50%"}>
                            <Typography className="card-field-12">
                              Total
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              ${Number(params?.total).toFixed(2)}
                            </Typography>
                          </Box>
                          <Box
                            flexDirection={"column"}
                            width={"50%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              MarketPlaceFee
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {params?.marketPlaceFee
                                ? "$" +
                                  Number(
                                    params?.marketPlaceFee?.fee_amount
                                  ).toFixed(2)
                                : "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box
                            flexDirection={"column"}
                            width={"100%"}
                            justifyContent={"flex-end"}
                            textAlign={"end"}
                          >
                            <Typography className="card-field-12">
                              Action
                            </Typography>
                            <Typography
                              className="card-field-14 text-ellipses"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <IconButton
                                sx={{ padding: 0 }}
                                onClick={() =>
                                  navigate({
                                    pathname: "/orderDetail",
                                    search: `?id=${params?._id}`,
                                  })
                                }
                              >
                                <OpenInNewIcon sx={{ color: "#1E1E1E" }} />
                              </IconButton>
                            </Typography>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </>
          )}
        </Box>
        <Stack spacing={2} sx={{ display: { xs: "block", md: "none" } }}>
          <Pagination
            totalCount={businessesData?.count}
            page={page}
            onPageChange={handlePageChange}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Orders;
