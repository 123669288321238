import React, { useEffect } from "react";
import Footer from "../../../../shared/components/footer";
import Header from "../../../../shared/components/header";
import Sidebar from "../../../../shared/components/sidebar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, Navigate } from "react-router-dom";
import { updateSession } from "../../../../services/auth";
import { useDispatch, useSelector } from "react-redux";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

export const ProtectedLayout = ({
  isAllowed,
  redirectPath = "/landing",
  children,
}) => {
  const dispatch = useDispatch();
  const { user, isSessionExpired } = useSelector((state) => state?.auth);
  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 10000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    if (!user?.email) {
      dispatch(updateSession(true));
    }
  };

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div>
      <Header />
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {children ? children : <Outlet />}
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default ProtectedLayout;
