import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, TextField, IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import { initialValues, Schema } from "./helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../shared/components/authLayout";
import { ClipLoader } from "react-spinners";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { addSocket, signIn, updateSession } from "../../services/auth";
import QRModal from "./QRModal";
import useDialogModal from "../../hooks/useDialogModal";
import ResendQRModal from "../ResendQR";
import { redirectPath } from "../../helpers/redirectPath";
import { socketServer } from "../../realtimeCommunication/socketConnection";

const Signin = () => {
  const dispatch = useDispatch();
  const { user, isSessionExpired, sockets } = useSelector(
    (state) => state?.auth
  );
  const loading = useSelector((state) => state?.auth?.userDetails?.loading);
  const [is_qr, setIs_Qr] = useState(false);
  const [QRModalDetailDialog, showPharmacyDetailDialog, closePharmacyDialog] =
    useDialogModal(QRModal);
  const [
    ResendQRModalDialog,
    showResendQRModalDialog,
    closeResendQRModalDialog,
  ] = useDialogModal(ResendQRModal);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [qrImg, setQRImage] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (isSessionExpired) {
      dispatch(updateSession(false));
    }
    if (sockets && sockets?.length > 0) {
      if (socketServer()) {
        socketServer().emit("forceDisconnect", sockets);
        dispatch(addSocket([]));
      }
    }
  }, []);

  useEffect(() => {
    redirectPath(user, navigate);
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => {
          dispatch(
            signIn(values, navigate, function (obj) {
              if (obj.response && obj.is_qr) {
                if (obj?.response?.data?.token != "false") {
                  setQRImage(obj?.response?.data?.token);
                  showPharmacyDetailDialog();
                  setIs_Qr(true);
                } else {
                  setIs_Qr(true);
                }
              }
            })
          );
        }}
        validationSchema={Schema}
      >
        {(props) => (
          <>
            <AuthLayout>
              <Box>
                <Typography variant="h4" gutterBottom>
                  {is_qr ? "Enter QR Code" : "Admin Login"}
                </Typography>
                <Typography color="text.secondary" variant="body2" gutterBottom>
                  {is_qr
                    ? "Use the token generated by the app in the code section"
                    : "  Sign in to your account"}
                </Typography>
              </Box>
              <form onSubmit={props.handleSubmit}>
                <Box
                  py={2}
                  sx={{
                    "& .MuiTextField-root": { my: 2 },
                  }}
                >
                  {!is_qr ? (
                    <>
                      <TextField
                        fullWidth
                        placeholder="Email"
                        value={props.values.email}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name="email"
                        error={
                          props.touched.email && Boolean(props.errors.email)
                        }
                        type="email"
                        helperText={props.touched.email && props.errors.email}
                        required
                      />
                      <TextField
                        fullWidth
                        placeholder="password"
                        value={props.values.password}
                        type={showPassword ? "text" : "password"}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="password"
                        error={
                          props.touched.password &&
                          Boolean(props.errors.password)
                        }
                        helperText={
                          props.touched.password && props.errors.password
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                    </>
                  ) : (
                    <>
                      <>
                        <TextField
                          fullWidth
                          placeholder="Enter Authenticator Code"
                          value={props.values.code}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          name="token"
                          type="text"
                        />

                        <Typography
                          variant="body2"
                          gutterBottom
                          onClick={() => showResendQRModalDialog()}
                        >
                          Didn't get the code
                        </Typography>
                      </>
                    </>
                  )}
                </Box>

                {!is_qr && (
                  <>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Remember me"
                      />
                      <Button
                        variant="text"
                        onClick={() =>
                          navigate("/forgotPassword", { replace: true })
                        }
                      >
                        Forget Password?
                      </Button>
                    </Box>
                  </>
                )}
                <Button
                  disabled={loading}
                  className="containedPrimary"
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={props.handleSubmit}
                >
                  {loading ? (
                    <ClipLoader size={25} color="white" loading />
                  ) : (
                    "Sign in"
                  )}
                </Button>
              </form>

              <QRModalDetailDialog qrImage={qrImg} />
              <ResendQRModalDialog />
            </AuthLayout>
          </>
        )}
      </Formik>
    </>
  );
};

export default Signin;
